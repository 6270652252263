import { getPublisherApiStatusState } from 'src/containers/settings/store'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

export default function Page() {
  const router = useRouter()
  const publisherApiStatus = useSelector(getPublisherApiStatusState)
  const url = publisherApiStatus == 200 ? '/sell-pages' : publisherApiStatus == 201 ? '/settings' : ''
  useEffect(() => {
    url && router.replace(url)
  }, [url])
  return null
}
